<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link
        class="ml-25"
        :href="url"
        target="_blank"
      >{{
        description
      }}</b-link>
      - Todos los Derechos Reservados - {{ footerText }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      footerText: `COPYRIGHT  © ${new Date().getFullYear()}`,
      description: JSON.parse(localStorage.getItem('userData')).company
        .companyStyle.footerInfo.description,
      url: JSON.parse(localStorage.getItem('userData')).company.companyStyle
        .footerInfo.url,
    }
  },
}
</script>
