export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'PieChartIcon',
    resource: 'DASHBOARD',
    action: 'manage',
  },
  {
    title: 'Levantamiento Datos',
    icon: 'EditIcon',
    resource: 'TECNICO',
    action: 'manage',
    route: 'levantamientoMobile',
  },
  {
    title: 'Sustituciones',
    icon: 'ListIcon',
    children: [
      {
        title: 'Levantamiento de Datos',
        icon: 'SettingsIcon',
        resource: 'LEVANTAMIENTO_INICIAL',
        action: 'manage',
        route: 'levantamiento-inicial',
      },
      {
        title: 'Tickets Asignados',
        icon: 'SettingsIcon',
        resource: 'LEVANTAMIENTO_INICIAL',
        action: 'manage',
        route: 'levantamientos-asignados',
      },
      {
        title: 'Tickets en Instalación',
        icon: 'SettingsIcon',
        resource: 'LEVANTAMIENTO_INICIAL',
        action: 'manage',
        route: 'levantamientos-instalacion',
      },
      {
        title: 'Tickets Migrados',
        icon: 'SettingsIcon',
        resource: 'LEVANTAMIENTO_INICIAL',
        action: 'manage',
        route: 'levantamientos-migrados',
      },
      {
        title: 'Tickets Finalizados',
        icon: 'SettingsIcon',
        resource: 'LEVANTAMIENTO_INICIAL',
        action: 'manage',
        route: 'levantamientos-finalizados',
      },
    ],
  },
  {
    title: 'Bodega',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'dashboard-bodega',
        icon: 'PieChartIcon',
        resource: 'TECNICO',
        action: 'manage',
      },
      {
        title: 'Luminarias',
        route: 'administrador-luminarias-apvn',
        icon: 'SunIcon',
        resource: 'TECNICO',
        action: 'manage',
      },
      {
        title: 'Mantenimiento de Bodega',
        icon: 'ActivityIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'mantenimiento-bodega',
      },
      {
        title: 'Despachos',
        icon: 'TruckIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'despacho-luminarias',
      },
      {
        title: 'Luminarias',
        route: 'administrador-luminarias',
        icon: 'SunIcon',
        resource: 'BODEGA',
        action: 'manage',
      },
      {
        title: 'Nemas',
        route: 'administrador-nemas',
        icon: 'RadioIcon',
        resource: 'BODEGA',
        action: 'manage',
      },
      {
        title: 'Postes',
        route: 'inventario-postes',
        icon: 'Edit2Icon',
        resource: 'BODEGA',
        action: 'manage',
      },
      {
        title: 'Brazos',
        route: 'inventario-brazos',
        icon: 'Edit2Icon',
        resource: 'BODEGA',
        action: 'manage',
      },
      {
        title: 'Herrajes',
        route: 'inventario-herrajes',
        icon: 'Edit2Icon',
        resource: 'BODEGA',
        action: 'manage',
      },
    ],
  },
  {
    title: 'Administración',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Administración Metricas',
        route: 'administrador-metricas',
        icon: 'SettingsIcon',
        resource: 'ADMIN',
        action: 'manage',
      },
      {
        title: 'Puntos Iluminación',
        route: 'puntos-iluminacion',
        icon: 'SunIcon',
        resource: 'PUNTOS',
        action: 'manage',
      },
      {
        title: 'Centros de Mando P&M',
        route: 'administrador-gabinetes',
        icon: 'SettingsIcon',
        resource: 'ADMIN',
        action: 'manage',
      },
      {
        title: 'Administración Gateway',
        route: 'administrador-gateway',
        icon: 'SettingsIcon',
        resource: 'ADMIN',
        action: 'manage',
      },
      {
        title: 'Ubicación de Puntos',
        route: 'postes-location',
        icon: 'MapPinIcon',
        resource: 'PUNTOS',
        action: 'manage',
      },
      {
        title: 'Usuarios',
        icon: 'UsersIcon',
        route: 'usuarios',
        resource: 'ADMIN_ANY',
        action: 'manage',
      },
      {
        title: 'Catálogos',
        icon: 'ListIcon',
        children: [
          {
            title: 'Tipos de Poste',
            route: 'catalogos-tipo-poste',
            resource: 'CATALOGO_ANY',
            action: 'manage',
          },
          {
            title: 'Materiales Postes',
            route: 'catalogos-tipo-poste-levantamiento',
            resource: 'LEVANTAMIENTO_INICIAL',
            action: 'manage',
          },
          {
            title: 'Propiedad Poste',
            route: 'catalogos-propiedades',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Tipos de Falla',
            route: 'catalogos-tipo-falla',
            resource: 'ADMIN',
            action: 'manage',
          },

          {
            title: 'Tipos de Lampara',
            route: 'catalogos-tipo-lamparas',
            resource: 'CATALOGO_ANY',
            action: 'manage',
          },
          {
            title: 'Clase de Lámpara',
            route: 'catalogos-clase-lampara',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Tecnología Lámpara',
            route: 'catalogos-tec-luminaria',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Potencias Lámpara',
            route: 'catalogos-potencia-lampara',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Dispositivo Lámparas',
            route: 'catalogos-dispositivo-lampara',
            resource: 'LEVANTAMIENTO_INICIAL',
            action: 'manage',
          },
          {
            title: 'Tipos de Brazo',
            route: 'catalogos-tipo-brazo',
            resource: 'CATALOGO_ANY',
            action: 'manage',
          },
          {
            title: 'Herrajes',
            route: 'catalogos-herrajes',
            resource: 'LEVANTAMIENTO_INICIAL',
            action: 'manage',
          },
          {
            title: 'Tipo de Conexiones',
            route: 'catalogos-tipo-conexion',
            resource: 'LEVANTAMIENTO_INICIAL',
            action: 'manage',
          },
          {
            title: 'Unidades Varias',
            route: 'catalogos-unidades-varias',
            resource: 'CATALOGO_ANY',
            action: 'manage',
          },
          {
            title: 'Trabajos Levantamiento',
            route: 'catalogos-trabajos-levantamiento',
            resource: 'CATALOGO_ANY',
            action: 'manage',
          },
          {
            title: 'Tamaños Accesorios',
            route: 'catalogos-tamano-accesorios',
            resource: 'LEVANTAMIENTO_INICIAL',
            action: 'manage',
          },
          {
            title: 'Grupos',
            route: 'catalogos-grupos',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Tipos de Falla BT',
            route: 'catalogos-tipo-falla-bt',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Tipo de Lámparas de Bodega',
            route: 'catalogos-tipo-lampara-bodega',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Trabajos de Conservación',
            route: 'catalogos-trabajos-conservacion',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Trabajos de Brecha de Iluminación',
            route: 'catalogos-trabajos-brecha',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Trabajos de Gateway',
            route: 'catalogos-trabajos-gateway',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Trabajos P&M',
            route: 'catalogos-trabajos-gabinete',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Fallas Iniciales de Ticket',
            route: 'catalogos-fallas-iniciales-ticket',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Fallas Lámparas de Bodega',
            route: 'catalogos-fallas-lamparas-bodega',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Pre-Diagnóstico Lámparas de Bodega',
            route: 'catalogos-prediagnostico-lamparas-bodega',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Pruebas Lámparas de Bodega',
            route: 'catalogos-pruebas-lamparas-bodega',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Reparaciones Laboratorio',
            route: 'catalogos-reparaciones-laboratorio',
            resource: 'ADMIN',
            action: 'manage',
          },
          {
            title: 'Trabajos Correctivos',
            route: 'catalogos-trabajos-correctivos',
            resource: 'ADMIN',
            action: 'manage',
          },
        ],
      },
      {
        title: 'Localización',
        icon: 'NavigationIcon',
        resource: 'ADMIN_ANY',
        action: 'manage',
        children: [
          {
            title: 'Departamentos',
            route: 'localizacion-departamentos',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Municipios',
            route: 'localizacion-municipios',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
          {
            title: 'Localidades',
            route: 'localizacion-localidades',
            resource: 'ADMIN_ANY',
            action: 'manage',
          },
        ],
      },
    ],
  },
  {
    title: 'Mantenimiento Preventivo',
    icon: 'ToolIcon',
    children: [
      {
        title: '1. Coordinación Aseguradora',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'coordinacion-aseguradora',
      },
      {
        title: '2. Falla de Linea BT',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'falla-linea-bt',
      },
      {
        title: '3. Inspecciones',
        icon: 'EyeIcon',
        resource: 'MONITOREO',
        action: 'manage',
        route: 'inspecciones-reporte',
      },
      {
        title: '4. Conservación',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'conservacion',
      },
      {
        title: '5. Brecha de Iluminación',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'brecha-iluminacion',
      },
      {
        title: '6. Conservación de Gateway',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'conservacion-gateway',
      },
      {
        title: '7. Conservación Centros de Mando P&M',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'conservacion-gabinetes',
      },
      {
        title: '8. Aplomado de Poste',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'aplomado-poste',
      },
      {
        title: '8. Seguimiento de Aplomado',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'seguimiento-aplomado',
      },
      {
        title: '9. Informe Mensual',
        icon: 'SlidersIcon',
        resource: 'TECNICO',
        action: 'manage',
        route: 'informe-mensual-index',
      },
      {
        title: 'Inspecciones',
        icon: 'EyeIcon',
        resource: 'SUPERVISOR',
        action: 'manage',
        route: 'inspecciones-reporte',
      },
      {
        title: 'Rutas Inspección',
        icon: 'MapIcon',
        resource: 'MONITOREO',
        action: 'manage',
        route: 'inspecciones-rutas',
      },
      /* {
        title: 'Métricas',
        icon: 'SlidersIcon',
        resource: 'ADMIN',
        action: 'manage',
        route: 'metricas',
      }, */
      {
        title: 'Nocturnas',
        icon: 'MoonIcon',
        resource: 'INSPECCIONES',
        action: 'manage',
        route: 'inspecciones-nocturnas',
      },
      {
        title: 'Diurnas',
        icon: 'SunIcon',
        resource: 'INSPECCIONES',
        action: 'manage',
        route: 'inspecciones-diurnas',
      },
    ],
  },
  {
    title: 'Mantenimiento Correctivo',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Tickets',
        route: 'tickets',
        icon: 'MonitorIcon',
        resource: 'CORRECTIVO',
        action: 'manage',
      },
      {
        title: 'Tickets',
        route: 'tickets',
        icon: 'MonitorIcon',
        resource: 'SUPERVISOR',
        action: 'manage',
      },
      {
        title: 'Tickets Asignados',
        route: 'fallas',
        icon: 'MonitorIcon',
        resource: 'TCORRECTIVO',
        action: 'manage',
      },
    ],
  },
  {
    title: 'Crecimiento de la Red',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Inspecciones Puntos Nuevos',
        icon: 'PlusCircleIcon',
        resource: 'AMPLIACIONES',
        action: 'manage',
        route: 'inspecciones-ampliaciones',
      },
      {
        title: 'Autorizaciones',
        icon: 'CheckIcon',
        resource: 'ADMIN',
        action: 'manage',
        route: 'inspecciones-autorizaciones',
      },
      {
        title: 'Ampliaciones Autorizadas',
        icon: 'UserPlusIcon',
        resource: 'AMPLIACIONES',
        action: 'manage',
        route: 'ampliaciones-autorizadas',
      },
      {
        title: 'Facturación',
        icon: 'FileTextIcon',
        resource: 'ADMIN',
        action: 'manage',
        route: 'inspecciones-facturacion',
      },
      {
        title: 'Pendientes de Cobro',
        icon: 'FileTextIcon',
        resource: 'ADMIN',
        action: 'manage',
        route: 'inspecciones-porCobrar',
      },
      {
        title: 'Puntos Nuevos Asignados',
        icon: 'PlusIcon',
        resource: 'INSPECCIONES',
        action: 'manage',
        route: 'inspecciones-ampliacion',
      },
    ],
  },
  {
    title: 'Empresas',
    route: 'empresas',
    icon: 'BriefcaseIcon',
    resource: 'SUPERADMIN',
    action: 'manage',
  },
  {
    title: 'Sustituciones Asignadas',
    route: 'tickets-asignados',
    icon: 'GitMergeIcon',
    resource: 'MIGRACION',
    action: 'manage',
  },
  {
    title: 'Luminarias Recolectadas',
    route: 'entrega-luminarias',
    icon: 'ActivityIcon',
    resource: 'LEVANTAMIENTO_INICIAL',
    action: 'manage',
  },
  {
    title: 'Entrega de Luminarias',
    route: 'tickets-entrega',
    icon: 'TruckIcon',
    resource: 'LEVANTAMIENTO_INICIAL',
    action: 'manage',
  },
]
